import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { CheckInput, DropdownInput, InputLabel, TextInput } from "sandhills-react-bootstrap-ui";
import { Model1071 } from "../../models/Model1071";

interface Props {
    model1071: Model1071;
    onChange: (value: any, property: string) => void;
}

const PrincipalOwner = (props: Props) => {
    const [numOwners, setNumOwners] = useState(props.model1071?.numOwners ?? 0)
    const numOwnersOptions = [
        { value: -1, label: "Select..."},
        { value: 0, label: "0" },
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
    ];

    const handleChange = (value: any, property: string, index?: number) => {
        // Handle the numOwners property
        if (property === 'numOwners') {
            setNumOwners(value);
            props.onChange(value, 'numOwners')
            return;
        }
    
        // Check if the property relates to Hispanic/Latino status and index is valid
        const isHispanicDecline = property.includes("NotHispanicOrLatino") || property.includes("DeclineHispanicOrLatino");
        const isLatino = property.includes("Latino");
        const isRaceDecline = property.includes("DeclineRace");
        const isRaceProperty = property.includes("AmericanOrAlaskanIndian") || property.includes("Asian") || property.includes("Black") || property.includes("White");
        const isGenderDecline = property.includes("GenderDecline");
        const isGenderProperty = property.includes("Gender");
        if (index !== undefined && index >= 0) {
            const propPrefix = `p${index}`;
    
            if (isHispanicDecline) {
                // Clear other related Hispanic/Latino inputs
                const relatedProperties = [
                    "HispanicOrLatino",
                    "LatinoMexican",
                    "LatinoCuban",
                    "LatinoPuertoRican",
                    "LatinoOther"
                ];
    
                relatedProperties.forEach(prop => props.onChange(false, `${propPrefix}${prop}`));
    
                if (property.includes("NotHispanic")) {
                    props.onChange(false, `${propPrefix}DeclineHispanicOrLatino`);
                }
                if (property.includes("DeclineHispanic")) {
                    props.onChange(false, `${propPrefix}NotHispanicOrLatino`);
                }
            } else if (isLatino) {
                // Clear Decline and NotHispanicOrLatino when selecting Latino properties
                props.onChange(false, `${propPrefix}DeclineHispanicOrLatino`);
                props.onChange(false, `${propPrefix}NotHispanicOrLatino`);
            } else if (isRaceDecline) {
                const raceKeys = Object.keys(props.model1071)
                .filter(key => key.startsWith(propPrefix) && (
                    key.includes("AmericanOrAlaskanIndian") ||
                    key.includes("Asian") ||
                    key.includes("Black") ||
                    key.includes("PacificIslander") || key.includes("NativeHawaiian") ||
                    key.includes("White")
                ));

                // Clear the values of all race-related properties
                raceKeys.forEach(raceKey => raceKey.includes("Description") ? props.onChange(null, raceKey) : props.onChange(false, raceKey));
            } else if (isRaceProperty && value === true) {
                // If a race property is set to true, clear DeclineRace
                props.onChange(false, `${propPrefix}DeclineRace`);
            } else if (isGenderDecline) {
                props.onChange("", propPrefix + "GenderDescription");
            } else if (isGenderProperty && !!value) {
                props.onChange(false, propPrefix + "GenderDecline");
            }
        }
        // Handle other property changes
        props.onChange(value, property);
    };
    

    // Function to render the owner components dynamically
    const renderOwners = () => {
        let ownerComponents = [];
        for (let i = 1; i <= numOwners; i++) {
            ownerComponents.push(
                <div className="mt-3 d-flex-vertical" key={i}>
                    <div className="text-condensed font-weight-bold text-uppercase text-dark-gray" style={{fontSize: "30px"}}>{`Principal Owner ${i}`}</div>
                    {i === 1 && (
                        <div className="mx-2">
                            <h5>Demographic information about principal owners</h5><br/>
                            <p>As a reminder, applicants are not required to provide this information but are encouraged to do so.
                                We cannot discriminate on the basis of any person’s ethnicity, race, or sex/gender. Additionally, we cannot discriminate on the basis of whether you provide this information. <br /><br />
                            Please fill out one sheet for each principal owner.</p>
                        </div>
                    ) }
                    <div className="mx-2 mt-4">
                        <h4>Are you Hispanic or Latino?</h4>
                    </div >
                    <div className="mt-0 mb-3 mx-2">
                        <small>i.e., What's your ethnicity? (Check one or more)</small>
                    </div >
                    <div className="mx-2 d-flex-vertical">
                        <CheckInput
                            className="mx-2 my-n2"
                            id={`p${i}-HispanicOrLatino`}
                            name={`p${i}HispanicOrLatino`}
                            value={props.model1071?.[`p${i}HispanicOrLatino`]}
                            label="Hispanic or Latino"
                            containerProps={{className: 'font-weight-bold'}}
                            onChange={(v) => handleChange(v, `p${i}HispanicOrLatino`, i)}
                        />
                        <div className="mx-4 my-n2">
                            <CheckInput
                                className="mx-2"
                                id={`p${i}-LatinoMexican`}
                                name={`p${i}LatinoMexican`}
                                value={props.model1071?.[`p${i}LatinoMexican`]}
                                label="Mexican"
                                onChange={(v) => handleChange(v, `p${i}LatinoMexican`, i)}
                            />
                        </div >
                        <div className="mx-4 my-n2">
                            <CheckInput
                                className="mx-2"
                                id={`p${i}-LatinoCuban`}
                                name={`p${i}LatinoCuban`}
                                value={props.model1071?.[`p${i}LatinoCuban`]}
                                label="Cuban"
                                onChange={(v) => handleChange(v, `p${i}LatinoCuban`, i)}
                                />
                        </div >
                        <div className="mx-4 my-n2">
                            <CheckInput
                            className="mx-2"
                            id={`p${i}-LatinoPuertoRican`}
                            name={`p${i}LatinoPuertoRican`}
                            value={props.model1071?.[`p${i}LatinoPuertoRican`]}
                            label="Puerto Rican"
                            onChange={(v) => handleChange(v, `p${i}LatinoPuertoRican`, i)}
                            />
                        </div >
                        <div className="mx-4 my-n2">
                            <Row className="mx-2 mb-1 d-flex flex-nowrap">
                                <CheckInput
                                        className="mr-2"
                                        id={`p${i}-LatinoOther`}
                                        name={`p${i}LatinoOther`}
                                        value={props.model1071?.[`p${i}LatinoOther`]}
                                        onChange={(v) => handleChange(v, `p${i}LatinoOther`, i)}
                                />
                                <div className="w-100 mb-2">
                                    Other Hispanic or Latino &nbsp; <small><i>(Please specify your origin, for example, Argentinean, Colombian, Dominican, Nicaraguan, Salvadoran, Spaniard, and so on)</i></small>
                                </div>
                               
                            </Row>
                            {props.model1071?.[`p${i}LatinoOther`] === true && (
                                <Row className="w-fit-content">
                                        <TextInput
                                        placeholder="Please specify your origin"
                                        className="mx-2 mb-0"
                                        id={`p${i}-LatinoOtherDescription`}
                                        name={`p${i}LatinoOtherDescription`}
                                        value={props.model1071?.[`p${i}LatinoOtherDescription`]}
                                        onChange={(v) => handleChange(v, `p${i}LatinoOtherDescription`, i)}
                                    />
                                </Row>
                            )}
                        </div >
                        <p className="my-2">
                            <i>— or —</i>
                        </p>
                        <Row className="mx-2 my-n2 d-flex flex-nowrap">
                            <CheckInput
                                id={`p${i}-NotHispanicOrLatino`}
                                name={`p${i}NotHispanicOrLatino`}
                                value={props.model1071?.[`p${i}NotHispanicOrLatino`]}
                                onChange={(v) => handleChange(v, `p${i}NotHispanicOrLatino`, i)}
                                aria-describedby={`p${i}-NotHispanicOrLatinoHint`}
                            />
                            <InputLabel className="mx-2 mb-2">
                                <strong>Not Hispanic or Latino</strong>
                                <div className="mt-1" id={`p${i}-NotHispanicOrLatinoHint`}>
                                    Selecting this option will clear any previous selections for this question
                                </div>
                            </InputLabel>
                        </Row>
                        <p className="my-2">
                            <i>— or —</i>
                        </p>
                        <Row className="mx-2 my-n2 d-flex flex-nowrap">
                            <CheckInput
                            id={`p${i}-DeclineHispanicOrLatino`}
                            name={`p${i}DeclineHispanicOrLatino`}
                            value={props.model1071?.[`p${i}DeclineHispanicOrLatino`]}
                            onChange={(v) => handleChange(v, `p${i}DeclineHispanicOrLatino`, i)}
                                aria-describedby={`p${i}-DeclineHispanicOrLatinoHint`}
                            />
                            <InputLabel className="mx-2 mb-2">
                                <strong>I do not wish to provide my ethnicity</strong>
                                <div className="mt-1" id={`p${i}-DeclineHispanicOrLatinoHint`}>
                                    Selecting this option will clear any previous selections for this question
                                </div>
                            </InputLabel>
                        </Row>
                        <hr/>
                    </div>
                    {/* Gender */}
                    <div className="mx-2">
                        <legend>What is your sex/gender?</legend>
                    </div >
                    <Row className="m-2">
                        <TextInput
                            className="m-2 w-100"
                            placeholder="Specify your sex / gender"
                            onChange={(v) => handleChange(v, `p${i}GenderDescription`, i)}
                            value={props.model1071?.[`p${i}GenderDescription`]}
                        />
                    </Row>
                    <p className="my-2">
                        <i>— or —</i>
                    </p>
                    <Row className="mx-2 d-flex flex-nowrap">
                        <CheckInput
                            className="mx-2"
                            id={`p${i}-GenderDecline`}
                            name={`p${i}GenderDecline`}
                            value={props.model1071?.[`p${i}GenderDecline`]}
                            onChange={(v) => handleChange(v, `p${i}GenderDecline`, i)}
                            aria-describedby={`p${i}GenderDeclineHint`}
                        />
                        <InputLabel className="mx-2">
                            <strong>I do not wish to provide my sex/gender</strong>
                            <div className="mt-1" id={`p${i}GenderDeclineHint`}>
                                Selecting this option will clear any previous selections for this question
                            </div>
                        </InputLabel>

                    </Row>
                    <hr />
                    <div className="mt-3 mx-2">
                        <legend>What is your race?</legend>
                    </div >
                    <div className="mt-0 mb-3 mx-2">
                        <small>(Check one or more)</small>
                    </div >
                    <div className="mx-2 d-flex-vertical">
                        <Row className="mx-2 d-flex flex-nowrap">
                            <CheckInput
                                className="my-n2"
                                id={`p${i}-AmericanOrAlaskanIndian`}
                                name={`p${i}AmericanOrAlaskanIndian`}
                                value={props.model1071?.[`p${i}AmericanOrAlaskanIndian`]}
                                onChange={(v) => handleChange(v, `p${i}AmericanOrAlaskanIndian`, i)}
                                />
                            <div className="w-100 mx-2 mb-2 my-1">
                                <strong>American or Alaskan Indian</strong> &nbsp; <small style={{fontSize: 'smaller'}}><i>(Please specify the name of your enrolled or principal tribe)</i></small>
                            </div>
                        </Row>
                            { props.model1071?.[`p${i}AmericanOrAlaskanIndian`] === true && (
                                <Row className="w-fit-content mx-2">
                                    <TextInput
                                        placeholder="Please specify your tribe"
                                        className="mx-2"
                                        id={`p${i}-AmericanOrAlaskanIndianDescription`}
                                        name={`p${i}AmericanOrAlaskanIndianDescription`}
                                        value={props.model1071?.[`p${i}AmericanOrAlaskanIndianDescription`]}
                                        onChange={(v) => handleChange(v, `p${i}AmericanOrAlaskanIndianDescription`, i)}
                                    />
                                </Row>
                            )/* Asian */} 
                        <CheckInput
                            className="mx-2 my-n2"
                            id={`p${i}-Asian`}
                            name={`p${i}Asian`}
                            value={props.model1071?.[`p${i}Asian`]}
                            label="Asian"
                            containerProps={{className: 'font-weight-bold'}}
                            onChange={(v) => handleChange(v, `p${i}Asian`, i)}
                            />
                        <div className="mx-4 my-n2">
                            <CheckInput
                                className="mx-2 my-n2"
                                id={`p${i}-AsianIndian`}
                                name={`p${i}AsianIndian`}
                                value={props.model1071?.[`p${i}AsianIndian`]}
                                label="Asian Indian"
                                onChange={(v) => handleChange(v, `p${i}AsianIndian`, i)}
                                />
                            <CheckInput
                                className="mx-2 my-n2"
                                id={`p${i}-AsianChinese`}
                                name={`p${i}AsianChinese`}
                                value={props.model1071?.[`p${i}AsianChinese`]}
                                label="Chinese"
                                onChange={(v) => handleChange(v, `p${i}AsianChinese`, i)}
                            />
                            <CheckInput
                                className="mx-2 my-n2"
                                id={`p${i}-AsianFilipino`}
                                name={`p${i}AsianFilipino`}
                                value={props.model1071?.[`p${i}AsianFilipino`]}
                                label="Filipino"
                                onChange={(v) => handleChange(v, `p${i}AsianFilipino`, i)}
                            />
                            <CheckInput
                                className="mx-2 my-n2"
                                id={`p${i}-AsianJapanese`}
                                name={`p${i}AsianJapanese`}
                                value={props.model1071?.[`p${i}AsianJapanese`]}
                                label="Japanese"
                                onChange={(v) => handleChange(v, `p${i}AsianJapanese`, i)}
                            />
                            <CheckInput
                                className="mx-2 my-n2"
                                id={`p${i}-AsianKorean`}
                                name={`p${i}AsianKorean`}
                                value={props.model1071?.[`p${i}AsianKorean`]}
                                label="Korean"
                                onChange={(v) => handleChange(v, `p${i}AsianKorean`, i)}
                            />
                            <CheckInput
                                className="mx-2 my-n2"
                                id={`p${i}-AsianVietnamese`}
                                name={`p${i}AsianVietnamese`}
                                value={props.model1071?.[`p${i}AsianVietnamese`]}
                                label="Vietnamese"
                                onChange={(v) => handleChange(v, `p${i}AsianVietnamese`, i)}
                            />
                            <Row className="mx-2 mb-1 d-flex flex-nowrap">
                                <CheckInput
                                    className="mr-2"
                                    id={`p${i}-AsianOther`}
                                    name={`p${i}AsianOther`}
                                    value={props.model1071?.[`p${i}AsianOther`]}
                                    onChange={(v) => handleChange(v, `p${i}AsianOther`, i)}
                                />
                                <div className="w-100 my-1">
                                    Other Asian &nbsp; <small style={{fontSize: 'smaller'}}><i>(Please specify your race, for example, Cambodian, Hmong, Laotian, Pakistani, Thai, and so on)</i></small>
                                </div>
                            </Row>
                            { props.model1071?.[`p${i}AsianOther`] === true && (
                                <Row className="w-fit-content">
                                    <TextInput
                                        placeholder="Please specify your race"
                                        className="mx-2"
                                        id={`p${i}-AsianOtherDescription`}
                                        name={`p${i}AsianOtherDescription`}
                                        value={props.model1071?.[`p${i}AsianOtherDescription`]}
                                        onChange={(v) => handleChange(v, `p${i}AsianOtherDescription`, i)}
                                        />
                                </Row>
                            )}
                            </div> {/* Black or African American */}
                            <div>
                                <CheckInput
                                    className="mx-2 my-n2"
                                    id={`p${i}-Black or African American`}
                                    name={`p${i}BlackOrAfricanAmerican`}
                                    value={props.model1071?.[`p${i}BlackOrAfricanAmerican`]}
                                    label="Black or African American"
                                    containerProps={{className: 'font-weight-bold'}}
                                    onChange={(v) => handleChange(v, `p${i}BlackOrAfricanAmerican`, i)}
                                    />
                                <div className="mx-4">
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-BlackAfricanAmerican`}
                                        name={`p${i}BlackAfricanAmerican`}
                                        value={props.model1071?.[`p${i}BlackAfricanAmerican`]}
                                        label="African American"
                                        onChange={(v) => handleChange(v, `p${i}BlackAfricanAmerican`, i)}
                                        />
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-BlackEthiopian`}
                                        name={`p${i}BlackEthiopian`}
                                        value={props.model1071?.[`p${i}BlackEthiopian`]}
                                        label="Ethiopian"
                                        onChange={(v) => handleChange(v, `p${i}BlackEthiopian`, i)}
                                    />
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-BlackHaitian`}
                                        name={`p${i}BlackHaitian`}
                                        value={props.model1071?.[`p${i}BlackHaitian`]}
                                        label="Haitian"
                                        onChange={(v) => handleChange(v, `p${i}BlackHaitian`, i)}
                                        />
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-BlackJamaican`}
                                        name={`p${i}BlackJamaican`}
                                        value={props.model1071?.[`p${i}BlackJamaican`]}
                                        label="Jamaican"
                                        onChange={(v) => handleChange(v, `p${i}BlackJamaican`, i)}
                                    />
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-BlackNigerian`}
                                        name={`p${i}BlackNigerian`}
                                        value={props.model1071?.[`p${i}BlackNigerian`]}
                                        label="Nigerian"
                                        onChange={(v) => handleChange(v, `p${i}BlackNigerian`, i)}
                                        />
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-BlackSomali`}
                                        name={`p${i}BlackSomali`}
                                        value={props.model1071?.[`p${i}BlackSomali`]}
                                        label="Somali"
                                        onChange={(v) => handleChange(v, `p${i}BlackSomali`, i)}
                                        />
                                    <Row className="mx-2 mb-1 d-flex flex-nowrap">
                                        <CheckInput
                                            className="mr-2"
                                            id={`p${i}-BlackOther`}
                                            name={`p${i}BlackOther`}
                                            value={props.model1071?.[`p${i}BlackOther`]}
                                            onChange={(v) => handleChange(v, `p${i}BlackOther`, i)}
                                        />
                                        <div className="w-100 mb-2">
                                        Other Black or African American &nbsp; <small style={{fontSize: 'smaller'}}><i>(Please specify your race, for example, Barbadian, Ghanaian, South African, and so on)</i></small>
                                        </div>
                                    </Row>
                                    { props.model1071?.[`p${i}BlackOther`] === true && (<Row className="w-fit-content">
                                        <TextInput
                                            placeholder="Please specify your race"
                                            className="mx-2"
                                            id={`p${i}-BlackOtherDescription`}
                                            name={`p${i}BlackOtherDescription`}
                                            value={props.model1071?.[`p${i}BlackOtherDescription`]}
                                            onChange={(v) => handleChange(v, `p${i}BlackOtherDescription`, i)}
                                            />
                                </Row>)}
                            </div>
                            <div>
                                <CheckInput
                                    className="mx-2 my-n2"
                                    id={`p${i}-Native Hawaiian or Other Pacific Islander`}
                                    name={`p${i}NativeHawaiianPacificIslander`}
                                    value={props.model1071?.[`p${i}NativeHawaiianPacificIslander`]}
                                    label="Native Hawaiian or Other Pacific Islander"
                                    containerProps={{ className: 'font-weight-bold' }}
                                    onChange={(v) => handleChange(v, `p${i}NativeHawaiianPacificIslander`, i)}
                                />
                                <div className="mx-4">
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-PacificIslanderGuamanian`}
                                        name={`p${i}PacificIslanderGuamanian`}
                                        value={props.model1071?.[`p${i}PacificIslanderGuamanian`]}
                                        label="Guamanian or Chamorro"
                                        onChange={(v) => handleChange(v, `p${i}PacificIslanderGuamanian`, i)}
                                    />
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-NativeHawaiian`}
                                        name={`p${i}NativeHawaiian`}
                                        value={props.model1071?.[`p${i}NativeHawaiian`]}
                                        label="Native Hawaiian"
                                        onChange={(v) => handleChange(v, `p${i}NativeHawaiian`, i)}
                                    />
                                    <CheckInput
                                        className="mx-2 my-n2"
                                        id={`p${i}-PacificIslanderSamoan`}
                                        name={`p${i}PacificIslanderSamoan`}
                                        value={props.model1071?.[`p${i}PacificIslanderSamoan`]}
                                        label="Samoan"
                                        onChange={(v) => handleChange(v, `p${i}PacificIslanderSamoan`, i)}
                                    />
                                    <Row className="mx-2 mb-1 d-flex flex-nowrap">
                                        <CheckInput
                                            className="mr-2"
                                            id={`p${i}-PacificIslanderOther`}
                                            name={`p${i}PacificIslanderOther`}
                                            value={props.model1071?.[`p${i}PacificIslanderOther`]}
                                            onChange={(v) => handleChange(v, `p${i}PacificIslanderOther`, i)}
                                        />
                                        <div className="w-100 my-1">
                                            Other Pacific Islander &nbsp; <small style={{fontSize: 'smaller'}}><i>(Please specify your race, for example, Fijian, Tongan, and so on)</i></small>
                                        </div>
                                    </Row>
                                    {props.model1071?.[`p${i}PacificIslanderOther`] === true && (
                                        <Row className="w-fit-content">
                                            <TextInput
                                                placeholder="Please specify your race"
                                                className="mx-2"
                                                id={`p${i}-PacificIslanderOtherDescription`}
                                                name={`p${i}PacificIslanderOtherDescription`}
                                                value={props.model1071?.[`p${i}PacificIslanderOtherDescription`]}
                                                onChange={(v) => handleChange(v, `p${i}PacificIslanderOtherDescription`, i)}
                                            />
                                        </Row>
                                    )}
                                </div >
                            </div>
                            <CheckInput
                                className="mx-2 my-n2"
                                id={`p${i}-White`}
                                name={`p${i}White`}
                                value={props.model1071?.[`p${i}White`]}
                                label="White"
                                containerProps={{className: 'font-weight-bold'}}
                                onChange={(v) => handleChange(v, `p${i}White`, i)}
                                />
                            <p className="my-2">
                                <i>— or —</i>
                            </p>
                        <Row className="mx-2 my-n2 d-flex flex-nowrap">
                            <CheckInput
                                className="mx-2"
                                id={`p${i}-DeclineRace`}
                                name={`p${i}DeclineRace`}
                                value={props.model1071?.[`p${i}DeclineRace`]}
                                onChange={(v) => handleChange(v, `p${i}DeclineRace`, i)}
                                aria-describedby={`${i}-DeclineRaceHint`}
                            />
                            <InputLabel className="mx-2">
                                <strong>I do not wish to provide my race</strong>
                                <div className="mt-1" id={`${i}-DeclineRaceHint`}>
                                    Selecting this option will clear any previous selections for this question
                                </div>
                            </InputLabel>
                        </Row>
                        </div>
                    <hr/>
                </div>
            </div>
        )
    };
    return ownerComponents;
}

    return (
        <div>
            <div className="text-condensed font-weight-bold text-uppercase text-dark-gray" style={{fontSize: "30px"}}>Number of Principal Owners</div>
            <div className="mx-2">
                <p>For the purposes of this form, a principal owner is any individual who owns 25 percent or more of the equity interest of a business. A business might not have any principal owners if, for example, it is not directly owned by any individuals (i.e., if it is not owned by another entity or entities) or if no individual directly owns at least 25 percent of the business.</p>
            </div >
            <Row className="mx-2">
                <DropdownInput
                    className="mx-2"
                    rawSelectProps={{ menuPlacement: "top" }}
                    onChange={(v) => { handleChange(v?.value, 'numOwners') }}
                    options={numOwnersOptions}
                    value={numOwnersOptions.find(o => o.value === props.model1071?.numOwners)}   
                >
                    <InputLabel>How many principal owners does your business have?</InputLabel>
                </DropdownInput>
            </Row>
            <hr/>
            <div>
                {renderOwners()}
            </div>
        </div >
    );
};

export default PrincipalOwner;
