import React, { useEffect, useState } from 'react';
import { InputLabel, InputNote, TextInput } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import ButtonSpinner from '../ButtonSpinner';

interface Props {
    value?: string,
    className?: string,
    onChange: (value: string) => void
}

const PREFIX = 'XXX-XX-';

const Last4SSNInput = (props: React.PropsWithChildren<Props>) => {

    const [displayedValue, setDisplayedValue] = useState(PREFIX + (props.value || ''));

    const handleChange = (value: string) => {

        let cleaned = value.replace(PREFIX, '').replace(/\D/g, '');

        // If over 4 characters, take the last 4
        if (cleaned.length > 4) {
            cleaned = cleaned.substring(cleaned.length - 4);
        }

        setDisplayedValue(PREFIX + cleaned);
        props.onChange(cleaned);
    }

    return (
        <TextInput maxLength={11} value={displayedValue} className={props.className} containerProps={{ className: 'label-colorize' }} onChange={handleChange}>
            {props.children}
        </TextInput>
    );
}

export default Last4SSNInput;