import React from 'react';
import { ListGroup } from "react-bootstrap";

const ChromeFaq = () => {
    return (
        <div>
            <h4 className='font-weight-bold'>Clear the cookie on a Chrome Browser</h4>
            <br />
            <ListGroup as='ol' numbered>
                <ListGroup.Item as='li'>
                    On your computer, open Chrome
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/chrome-logo.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    At the top right, click More
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/vertical-dots.png'} alt='Browser Icon' />
                    then click on Settings
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/settings-icon.png'} alt='Browser Icon' />
                    .
                </ListGroup.Item>
                <ListGroup.Item as='li'>Click Privacy and security {'>'} Cookies and other site data.</ListGroup.Item>
                <ListGroup.Item as='li'>Scroll down and Click See all site data and permissions.</ListGroup.Item>
                <ListGroup.Item as='li'>
                    At the top right, search for the Cookie associated to the router by typing the name express-simple.
                    <img style={{ height: '100%', width: '100%' }} src={'images/dbcs_faq/chrome-settings.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    To the right of the express-simple.com cookie, click the Remove Icon
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/trash-icon.png'} alt='Browser Icon' />
                    .
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    To confirm, click Clear when prompted
                    <img style={{ height: '100%', width: '100%' }} src={'images/dbcs_faq/chrome-clear-confirmation.png'} alt='Browser Icon' />
                </ListGroup.Item>
            </ListGroup>
            <br />
            <p>In addition to completing the steps above, we recommend you also complete the following steps to check for any 3rd party ad blockers,
                antivirus or malware plugins that have been installed on your browser.</p>
            <br />
            <ListGroup as='ol' numbered>
                <ListGroup.Item as='li'>
                    On your computer, open Chrome
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/chrome-logo.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    At the top right, click More
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/vertical-dots.png'} alt='Browser Icon' />
                    then click on More tools {'>'} Extensions.
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    Review your installed extensions and identify any that are ad blockers, antivirus or malware plugins. Then click
                    Details to make your changes to each by either:
                    <ListGroup>
                        <ListGroup.Item>
                            Turn off: turn the extension off.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Modify the Allow site access: On the extension, click Details. Next to {'"'}Allow this extension to read and change all your data
                            on websites you visit,{'"'} change the extension's site access to either On specific sites, or On all sites. If you choose On specific
                            sites you will need to add express-simple.com as an allowed site.
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
            </ListGroup>
            <br />
            <div style={{ backgroundColor: 'lightgray', fontSize: '20px' }}>
                <p style={{ margin: '10px' }}>
                    For all application status updates, please contact the appropriate lender.
                    <br /><br />
                    If you continue to experience technical issues after completing the steps above, please contact us at:
                    <br />
                    techsupport@gocurrency.com
                </p>
            </div>
        </div>
    );
}

export default ChromeFaq;