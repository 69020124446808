import React from 'react';
import { ListGroup } from "react-bootstrap";

const EdgeFaq = () => {
    return (
        <div>
            <h4 className='font-weight-bold'>Clear the cookie on a Microsoft Edge Browser</h4>
            <br />
            <ListGroup as='ol' numbered>
                <ListGroup.Item as='li'>
                    On your computer, open Chrome
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/edge-logo.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    At the top right, click More
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/horizontal-dots.png'} alt='Browser Icon' />
                    then click on Settings
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/settings-icon.png'} alt='Browser Icon' />
                    .
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    In the left menu under Settings, Click Cookies and site permissions<br />
                    <img style={{ width: '35%' }} src={'images/dbcs_faq/edge-cookies.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>Click Manage and delete cookies and site data.</ListGroup.Item>
                <ListGroup.Item as='li'>Click See all cookies and site data.</ListGroup.Item>
                <ListGroup.Item as='li'>Search for {'"'}express-simple{'"'} in the search bar</ListGroup.Item>
                <ListGroup.Item as='li'>
                    Expand the express-simple.com cookie by clicking the down arrow at the far right
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/expand-down.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    Then click the trash can icon 
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/trash-icon.png'} alt='Browser Icon' />
                    to delete the cookie.
                </ListGroup.Item>
            </ListGroup>
            <br />
            <p className='font-weight-bold ml-2'>Edge users may also want to check their Trakcing prevention settings by follwoing these steps:</p>
            <ListGroup as='ol' numbered>
                <ListGroup.Item as='li'>
                    On your computer, open Edge
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/edge-logo.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    At the top right, click More
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/horizontal-dots.png'} alt='Browser Icon' />
                    then click on Settings
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/settings-icon.png'} alt='Browser Icon' />
                    .
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    In the left menu under Settings, Click Privacy, search, and services<br />
                    <img style={{ width: '35%' }} src={'images/dbcs_faq/edge-privacy.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    Click on basic or Balanced option.
                </ListGroup.Item>
            </ListGroup>
            <br />
            <div style={{ backgroundColor: 'lightgray', fontSize: '20px' }}>
                <p style={{ margin: '10px' }}>
                    For all application status updates, please contact the appropriate lender.
                    <br /><br />
                    If you continue to experience technical issues after completing the steps above, please contact us at:
                    <br />
                    techsupport@gocurrency.com
                </p>
            </div>
        </div>
    );
}

export default EdgeFaq;