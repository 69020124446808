import React, { useContext, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { AppContext } from '../../AppContext';
import { VendorStep } from '../../models/VendorStep';
import ButtonSpinner from '../../shared/ButtonSpinner';
import { StepQuestion } from '../../models/StepQuestion';
import Question from './Question';

interface Props {
    visible: boolean,
    loading: boolean,
    step: VendorStep,
    allFlowQuestions: StepQuestion[],
    inputValueDictionary: { [inputDefinitionID: number]: any },
    questionValueDictionary: { [inputDefinitionID: number]: any },
    isLastStep: boolean,
    errors: { [key: number]: string | true | undefined },
    disablePrevious: boolean,
    nextButtonLabel?: string,
    onQuestionValueChange: (question: StepQuestion, value: any) => void,
    onStartOver?: () => void,
    onBack?: () => void,
    onNext?: () => void
}

const Step = (props: Props) => {
    const context = useContext(AppContext);

    return (
        <Card className={`w-100 ${!props.visible && 'd-none'} card-background`}>
            <Card.Header style={{ fontSize: 30 }} className='text-center text-condensed font-weight-bold text-uppercase text-dark-gray card-header'>
                {props.step.label}
            </Card.Header>
            <Card.Body className='card-background'>

                {props.step.questionGroups && props.step.questionGroups.map((group, gi) => {
                    if (!group.show)
                        return null;

                    const upperDivider = gi !== 0 && group.questions.length > 1;

                    return (
                        <React.Fragment key={`group-${gi}-${group.groupID}`}>
                            {upperDivider && <hr />}
                            {/* {group.questions.filter(x => x.show).length > 1 && <h5><u>{group.groupName}</u></h5>} */}
                            <Row>
                                {group.questions && group.questions.map((question, qi) => {
                                    // Set a minimum allowed width per question
                                    let colWidth = Math.floor(12 / group.questions.filter(x => x.dataType.toLowerCase() !== 'display only').length);
                                    if (colWidth < 4)
                                        colWidth = 4;
                                    if (question.dataType.toLowerCase() === 'display only')
                                        colWidth = 12;

                                    return (
                                        <Question
                                            id={`question-${qi}-${question.inputDefinitionID}`}
                                            key={`question-${qi}-${question.inputDefinitionID}`}
                                            allFlowQuestions={props.allFlowQuestions}
                                            columnWidth={colWidth}
                                            errors={props.errors}
                                            onQuestionValueChange={props.onQuestionValueChange}
                                            question={question}
                                            group={group}
                                            inputValueDictionary={props.inputValueDictionary}
                                            questionValueDictionary={props.questionValueDictionary}
                                        />
                                    )
                                }
                                )}
                            </Row>
                        </React.Fragment>
                    );
                })}
            </Card.Body>
            <Card.Footer className='d-flex flex-row-reverse justify-content-between card-background'>
                <div className='d-inline-flex flex-row-reverse'>
                    <Button disabled={props.loading} variant='primary' onClick={props.onNext}>{props.nextButtonLabel ?? 'Next'}{props.loading && <ButtonSpinner />}</Button>
                    {props.onBack && <Button disabled={props.loading || props.disablePrevious} variant='secondary' className='mr-3' onClick={props.onBack}>Back</Button>}
                </div>
                <div>
                    {props.onStartOver && <Button disabled={props.loading || props.disablePrevious} variant='link' onClick={props.onStartOver}>Start Over</Button>}
                </div>
            </Card.Footer>
        </Card>
    );
}

export default Step;