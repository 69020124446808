import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

interface Props {
    browserName: string,
    browserIconPath: string,
    onClick: () => void,
    active?: boolean,
    className?: string
}

const BrowserSelect = (props: Props) => {

    return (

        <>
            <style type="text/css">
                {`
                    .btn-browser {
                      background-color: transparent;
                      color: black;
                    }
                    .btn-browser:hover {
                        background-color: lightgray;
                        color: black;
                    }
                    .btn-browser:focus {
                        background-color: lightgray;
                        color: black;
                        box-shadow: none !important;
                    }
                    .btn-browser.active {
                        background-color: lightgray;
                        color: black;
                        box-shadow: none !important;
                    }
                    
                `}
            </style>

            <Button variant='browser' className={props.className} onClick={props.onClick} active={props.active}>
                {props.browserName}
                <img style={{ height: '1.5rem', marginLeft: '5px' }} src={props.browserIconPath} alt='Browser Icon' />
            </Button>
        </>
    );

}

export default BrowserSelect;