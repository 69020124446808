import React, { useEffect, useState } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import FlowProvider from '../../providers/FlowProvider';

interface Props {
    value?: SelectOption,
    className?: string,
    onChange: (value: { label: string, value: any } | null) => void
}

const IndustryDropdown = (props: React.PropsWithChildren<Props>) => {

    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        FlowProvider.GetIndustries()
            .then((result) => {
                if (result) {
                    result.options.push({
                        label: 'Other',
                        value: 0
                    } as SelectOption)

                    setOptions(result.options);

                    if (props.value !== null && props.value !== undefined && result.options.findIndex(x => x.value === props.value?.value) === -1) {
                        props.onChange(null);
                    }
                } else if (props.value !== null && props.value !== undefined) {
                    props.onChange(null);
                }
            })
    }, []);

    // Browser autofill will set the value to the label instead of the value
    // useEffect(() => {
    //     if (props.value && !Number.isInteger(props.value.value)) {
    //         let found = options.find(x => x.label.toLowerCase() === props.value?.label?.toLowerCase());

    //         if (found) {
    //             props.onChange(found);
    //         }
    //     }
    // }, [props.value]);

    return (
        <DropdownInput searchable value={props.value} className={props.className} containerProps={{ className: 'label-colorize' }} onChange={props.onChange} options={options}>
            {props.children}
        </DropdownInput>
    )
}

export default IndustryDropdown;