import React, { useEffect, useState } from 'react';
import { Route, Routes, matchPath, useLocation, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import { v4 as uuid, validate } from 'uuid';
import { AppContext } from './AppContext';
import ThemeProvider from './ThemeProvider';
import Layout from './layout/Layout';
import { AppInitialization } from './models/AppInitialization';
import { Theme } from './models/Theme';
import AdditionalGuarantorDetail from './pages/applicationdetail/AdditionalGuarantorDetail';
import ApplicationDetail from './pages/applicationdetail/ApplicationDetail';
import Home from './pages/home/Home';
import NotFound from './pages/notfound/NotFound';
import AppProvider from './providers/AppProvider';
import SearchParamUtility from './utilities/SearchParamUtitlity';
import CookiesBanner from './pages/home/CookiesBanner';
import TenSeventyOne from './pages/tenseventyone/TenSeventyOne';

const cookies = new Cookies();
const CURRENCY_UNIQUE_ID_KEY = 'CURR_UNQ_ID';


// Only the colors matter here
const defaultTheme: Theme = {
    primary: '#003b5c',
    secondary: '#b3a369'
} as Theme;

const defaultVendorSettings = {
    "showFaqButton": false,
    "showFooter": true,
    "showHeading": true,
    "showCookieBanner": true
}

const App = () => {
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(true);
    const [app, setApp] = useState<AppInitialization>()
    const [fatalError, setFatalError] = useState(false);
    const [uniqueClientID, setUniqueClientID] = useState<string>('');

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const vendorID = parseInt(SearchParamUtility.getCaseInsensitiveParam(searchParams, 'vendorID') ?? '0');
        const TLPID = parseInt(SearchParamUtility.getCaseInsensitiveParam(searchParams, 'TLPID') ?? '0');
        const userID = SearchParamUtility.getCaseInsensitiveParam(searchParams, 'userID');

        let value = userID && validate(userID) ? userID : cookies.get(CURRENCY_UNIQUE_ID_KEY);
        if (!value) {
            value = uuid();
        }
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1); // Broswers only support max 1 year (or 400 days) expiry
        cookies.set(CURRENCY_UNIQUE_ID_KEY, value, { path: '/', expires: date }); // Set expiry so cookie is not deleted when browser is closed
        setUniqueClientID(value);

        AppProvider.InitializeApp(vendorID > 0 ? vendorID : null, TLPID > 0 ? TLPID : null, tryGetApplicationGUIDFromRoute(), null)
            .then((result) => {
                if (result) {
                    setApp(result);
                } else {
                    setFatalError(true);
                }
            }).finally(() => {
                setLoading(false);
            });

    }, []);

    // Unfortunately this is necessary because the init function exists outside of the Route
    // Should've just made the guid a query param originally, but it's too late now
    const tryGetApplicationGUIDFromRoute = (): string | null => {
        let params = matchPath('/application/:applicationGUID', pathname);
        if (!params)
            params = matchPath('/application/vendor/:applicationGUID', pathname);
        if (!params)
            params = matchPath('/application/guarantor/:applicationGUID', pathname);
        if (!params)
            params = matchPath('/application/1071/:applicationGUID', pathname);

        return params?.params?.applicationGUID ?? null;
    }

    return (
        <AppContext.Provider value={{
            vendorID: app?.vendorID || 0,
            topLevelPartnerVendorID: app?.topLevelPartnerVendorID || 0,
            highestTopLevelPartnerVendorID: app?.highestTopLevelPartnerVendorID || 0,
            defaultCurrencyCode: app?.defaultCurrencyCode || 'USD',
            theme: app?.theme || defaultTheme,
            style: app?.style || '',
            logoURL: app?.logoURL || '',
            phoneNumber: app?.phoneNumber || '',
            supportEmail: app?.supportEmail || '',
            uniqueClientID,
            vendorSettings: app?.vendorSettings || defaultVendorSettings
        }}>
            <ThemeProvider />
            <ToastContainer position='top-center' pauseOnHover autoClose={5000} />
            <Layout fatalError={fatalError} loading={loading}>
                <Routes>
                    <Route path='*' element={<NotFound />} />

                    <Route path='/' element={<Home />} />

                    <Route path='/application'>
                        {/* <Route path='login' element={<Login />} />
            <Route path='list' element={<ApplicationList />} /> */}
            <Route path='guarantor/:applicationGUID' element={<AdditionalGuarantorDetail />} />
            <Route path='vendor/:applicationGUID' element={<ApplicationDetail isForVendor />} />
            <Route path=':applicationGUID' element={<ApplicationDetail />} />
            <Route path='1071/:applicationGUID' element={<TenSeventyOne />} />
          </Route>

                </Routes>
                <CookiesBanner />
            </Layout>

        </AppContext.Provider>
    );
};

export default App;