import { useParams } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { CheckInput, DropdownInput, InputLabel } from "sandhills-react-bootstrap-ui";
import PrincipalOwner from "./PrincipalOwner";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Model1071 } from "../../models/Model1071";
import ApplicationProvider from "../../providers/ApplicationProvider";
import PageLoading from "../../shared/PageLoading";
import AnimatedCheck from "../../shared/AnimatedCheck";
import AnimatedExclamation from "../../shared/AnimatedExclamation";
import { AppContext } from "../../AppContext";

const TenSeventyOne = () => {
    const context = useContext(AppContext);

    const { applicationGUID } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [model1071, setModel1071] = useState<Model1071>({} as Model1071);
    const [isValid, setIsValid] = useState<boolean>(false);

    const numWorkerOptions = [
        { value: -1, label: 'Select...' },
        { value: 0, label: 'No Workers' },
        { value: 1, label: '1 to 4 Workers' },
        { value: 2, label: '5 to 9 Workers' },
        { value: 3, label: '10 to 19 Workers' },
        { value: 4, label: '20 to 49 Workers' },
        { value: 5, label: '50 to 99 Workers' },
        { value: 6, label: '100 to 249 Workers' },
        { value: 7, label: '250 to 499 Workers' },
        { value: 8, label: '500+ Workers' }
    ]

    useEffect(() => {
        setLoading(true);
        if (applicationGUID) {
            ApplicationProvider.GetTenSeventyOneData(applicationGUID).then((data) => {
                if (data?.success === true) {
                    setModel1071(data);
                }
            }).finally(() => setLoading(false));
        }
    }, [])

    const handleSubmit = () => {
        setLoading(true);
        ApplicationProvider.SaveTenSeventyOneData(model1071).then((result) => {
            if (result?.success === true) {
                setModel1071(result);
            }
        }).finally(() => setLoading(false));
    }

    // Function to run validation and update button status
    const validateForm = (model: any) => {
        const isValid = validateModel(model);
        setIsValid(isValid);
    };

    // Handle ownership status changes
    const handleOwnershipStatusChange = (value: boolean, name: string) => {
        setModel1071((prevState: any) => {
            const updatedState = name === 'noneDesignatedOwned' || name === 'declineOwnedResponse'
                ? {
                    ...prevState,
                    minorityOwned: false,
                    womenOwned: false,
                    lgbtqiOwned: false,
                    noneDesignatedOwned: name === 'noneDesignatedOwned' ? value : false,
                    declineOwnedResponse: name === 'declineOwnedResponse' ? value : false
                }
                : {
                    ...prevState,
                    [name]: value,
                    noneDesignatedOwned: false,
                    declineOwnedResponse: false
                };

            validateForm(updatedState); // Validate after state update
            return updatedState;
        });
    };

    // Handle general field changes
    const handleChange = (value: any, property: string) => {
        setModel1071((prevState: any) => {
            const updatedState = { ...prevState, [property]: value };
            validateForm(updatedState); // Validate after state update
            return updatedState;
        });
    };

    const getFinalView = () => {
        if (model1071?.isCompleted === true) {
            return (<div className='text-center'>
                        <h3 className='text-uppercase text-condensed'>Thank you</h3>
                        <p>Thank you for completing this survey. We have recorded your responses.</p>
                        <AnimatedCheck className='text-secondary'/>
                        <h4>Reference #: <b>{model1071.applicationID}</b></h4>
                        <h4>Thank you for applying with {model1071.topLevelPartnerName}.</h4>
                    </div>
                )
        }

        if (model1071?.isExpired === true) {
            return (<div className='text-center'>
                        <h3 className='text-uppercase text-condensed'>This link has expired</h3>
                        <p>This link has expired. We are no longer collecting information for this application.</p>
                        <AnimatedExclamation className='text-secondary'/>
                        <h4>Reference #: <b>{model1071.applicationID}</b></h4>
                        <h4>Thank you for applying with {model1071.topLevelPartnerName}.</h4>
                </div>
            )
        }
    }

    const validateModel = (model: Model1071): boolean => {
        // Validate numWorkers
        if (model.numWorkers < 0) return false;

        // Validate Business Ownership Status
        if (
            !(
                model.minorityOwned ||
                model.womenOwned ||
                model.lgbtqiOwned ||
                model.noneDesignatedOwned ||
                model.declineOwnedResponse
            )
        ) return false;

        // Validate Principal Owners dynamically (assuming P1, P2, etc. up to numOwners)
        for (let i = 1; i <= model.numOwners; i++) {
            const prefix = `p${i}`;

            // Ethnicity Check
            if (
                !model[`${prefix}HispanicOrLatino`] &&
                !model[`${prefix}LatinoMexican`] &&
                !model[`${prefix}LatinoCuban`] &&
                !model[`${prefix}LatinoPuertoRican`] &&
                !model[`${prefix}LatinoOther`] &&
                !model[`${prefix}NotHispanicOrLatino`] &&
                !model[`${prefix}DeclineHispanicOrLatino`]
            ) return false;

            // Other Ethnicity & Description Checks
            if (model[`${prefix}LatinoOther`] && !model[`${prefix}LatinoOtherDescription`]) return false;

            // Race Check
            if (
                !model[`${prefix}DeclineRace`] &&
                !model[`${prefix}AmericanOrAlaskanIndian`] &&
                !model[`${prefix}Asian`] &&
                !model[`${prefix}AsianIndian`] &&
                !model[`${prefix}AsianChinese`] &&
                !model[`${prefix}AsianFilipino`] &&
                !model[`${prefix}AsianJapanese`] &&
                !model[`${prefix}AsianKorean`] &&
                !model[`${prefix}AsianVietnamese`] &&
                !model[`${prefix}AsianOther`] &&
                !model[`${prefix}BlackOrAfricanAmerican`] &&
                !model[`${prefix}BlackAfricanAmerican`] &&
                !model[`${prefix}BlackEthiopian`] &&
                !model[`${prefix}BlackHaitian`] &&
                !model[`${prefix}BlackJamaican`] &&
                !model[`${prefix}BlackNigerian`] &&
                !model[`${prefix}BlackSomali`] &&
                !model[`${prefix}BlackOther`] &&
                !model[`${prefix}NativeHawaiianPacificIslander`] &&
                !model[`${prefix}NativeHawaiian`] &&
                !model[`${prefix}PacificIslanderGuamanian`] &&
                !model[`${prefix}PacificIslanderSamoan`] &&
                !model[`${prefix}PacificIslanderOther`] &&
                !model[`${prefix}White`]
            ) return false;

            // Other Race & Description Checks
            if (model[`${prefix}AsianOther`] && !model[`${prefix}AsianOtherDescription`]) return false;
            if (model[`${prefix}AmericanOrAlaskanIndian`] && !model[`${prefix}AmericanOrAlaskanIndianDescription`]) return false;
            if (model[`${prefix}BlackOther`] && !model[`${prefix}BlackOtherDescription`]) return false;
            if (model[`${prefix}PacificIslanderOther`] && !model[`${prefix}PacificIslanderOtherDescription`]) return false;

            // Gender Check
            if (!model[`${prefix}GenderDecline`] && !model[`${prefix}GenderDescription`]) return false;
        }

        // If there are errors, return false (validation fails), otherwise true
        return true;
    };

    return (
        <div className='w-100 home-container d-flex flex-column align-items-center justify-content-center bg-tint'>
            <Col style={{ backgroundColor: '#EAECED' }} className='w-100 home-container d-flex flex-column align-items-center justify-content-around'>
                <Row className="w-100 home-container d-flex flex-column align-items-center justify-content-center p-2">
                    <div className="d-flex flex-column align-items-center">
                        {context.vendorSettings.showHeading &&
                            <div style={{ width: 300 }} className='img-header text-center'>
                                <img style={{ maxWidth: 300 }} src='images/currency.png' />
                                <span style={{ fontSize: 22 }} className='text-uppercase text-condensed'>Welcome to fast and easy financing</span>
                            </div>
                        }
                    </div>
                </Row>
                <Row className="w-100 home-container d-flex flex-column align-items-center justify-content-center">
                    <Card style={{ minHeight: '60vh' }} className='p-4 d-flex flex-column step-container shadow'>
                        {loading ? <PageLoading /> : model1071.isCompleted || model1071.isExpired ? getFinalView() : (<>

                            <p><strong>Federal law requires that we request the following information</strong> to help ensure that all small businesses applying for loans and other kinds of credit are treated fairly, and that communities' small business credit needs are met.</p>
                            <p>One or more employees or officers involved in making a determination concerning your application may have access to the information provided on this form.</p>
                            <ul className="mt-2 mb-3">
                                <li>However, <strong>FEDERAL LAW PROHIBITS DISCRIMINATION</strong> on the basis of your answers on this form.</li>
                                <li>Additionally, we cannot discriminate on the basis of whether you provide this information.</li>
                                <li>While you are not required to provide this information, we encourage you to do so.</li>
                                <li>Importantly, our staff are not permitted to discourage you in any way from responding to these questions.</li>
                            </ul>
                            <p><strong>Filling out this form will help to ensure that ALL small business owners are treated fairly.</strong></p>
                            <hr />
                            {/* Number of Workers */}
                            <div className="text-condensed font-weight-bold text-uppercase text-dark-gray" style={{ fontSize: 30 }}>Number of Workers</div>
                            <Row className='mx-2'>
                                <p>Please indicate the range of number of workers for your small business. Include full-time, part-time, and seasonal workers as well as contractors working primarily for your business, but do not include principal owners.  </p>
                            </Row>
                            <Row className='mx-2'>
                                <DropdownInput containerProps={{ style: { maxHeight: 'none', overflow: 'visible' } }} onChange={(v) => { handleChange(v?.value?.toString(), 'numWorkers') }} options={numWorkerOptions}>
                                    <InputLabel>How many workers does your business have?</InputLabel>
                                </DropdownInput>
                            </Row>
                            <hr />
                            {/* Business Ownership Status */}
                            <div className="text-condensed font-weight-bold text-uppercase text-dark-gray" style={{ fontSize: "30px" }}>Business Ownership Status</div>
                            <p className="mx-2">Please indicate the business ownership status of your small business. For the purposes of this form, your business is a minority-owned, women-owned, or LGBTQI+-owned business if one or more minorities,* women, or LGBTQI+ individuals (i) directly or indirectly own or control more than 50 percent of the business AND (ii) receive more than 50 percent of the net profits/losses of the business.</p>
                            <h4 className="mx-2 mb-2">What is your business ownership status?</h4>
                            <small className="mt-0 mb-4 mx-2">(Check one or more of the options below)</small>
                            <div style={{ lineHeight: 0.9 }}>
                                <Row className="mx-2 my-n2">
                                    <CheckInput
                                        className="mx-2"
                                        id="business-owner-minority"
                                        name="minorityOwned"
                                        value={model1071.minorityOwned}
                                        label="Minority-owned business"
                                        containerProps={{ className: 'font-weight-bold' }}
                                        onChange={(v) => handleOwnershipStatusChange(v, 'minorityOwned')}
                                    />
                                </Row>
                                <Row className="mx-2 my-n2">
                                    <CheckInput
                                        className="mx-2"
                                        id="business-owner-women"
                                        name="womenOwned"
                                        value={model1071.womenOwned}
                                        onChange={(v) => handleOwnershipStatusChange(v, "womenOwned")}
                                        label="Women-owned business"
                                        containerProps={{ className: 'font-weight-bold' }}
                                    />
                                </Row>
                                <Row className="mx-2 my-n2">
                                    <CheckInput
                                        className="mx-2"
                                        id="business-owner-lgbtqi"
                                        name="lgbtqiOwned"
                                        value={model1071.lgbtqiOwned}
                                        label="LGTBQI+-owned business"
                                        containerProps={{ className: 'font-weight-bold' }}
                                        onChange={(v) => handleOwnershipStatusChange(v, "lgbtqiOwned")}
                                    />
                                </Row>
                                <p className="my-1">
                                    <i>— or —</i>
                                </p>
                                <Row className="m-2 d-flex flex-nowrap">
                                    <CheckInput
                                        className="mx-2"
                                        id="business-owner-none"
                                        name="noneDesignatedOwned"
                                        value={model1071.noneDesignatedOwned}
                                        onChange={(v) => handleOwnershipStatusChange(v, 'noneDesignatedOwned')}
                                        aria-describedby="business-owner-none-hint"
                                    />
                                    <InputLabel className="mx-2">
                                        <strong>None of these apply</strong>
                                        <div className="mt-1" id="business-owner-none-hint">
                                            Selecting this option will clear any previous selections for this question
                                        </div>
                                    </InputLabel>
                                </Row>
                                <p className="my-1">
                                    <i>— or —</i>
                                </p>
                                <Row className="m-2 d-flex flex-nowrap">
                                    <CheckInput
                                        className="mx-2"
                                        id="business-owner-decline"
                                        name="declineOwnedResponse"
                                        value={model1071.declineOwnedResponse}
                                        onChange={(v) => handleOwnershipStatusChange(v, 'declineOwnedResponse')}
                                        aria-describedby="business-owner-decline-hint"
                                    />
                                    <InputLabel className="mx-2">
                                        <strong>I do not wish to provide this information</strong>
                                        <div className="mt-1" id="business-owner-decline-hint">
                                            Selecting this option will clear any previous selections for this question
                                        </div>
                                    </InputLabel>
                                </Row>
                                <small className="my-2">*Minority means Hispanic or Latino, American Indian or Alaska Native, Asian, Black or African American, or Native Hawaiian or Other Pacific Islander. A multi-racial or multi-ethnic individual is a minority for this purpose.</small>
                                <hr />
                            </div>

                            {/* Number of Principle Owners */}
                            <PrincipalOwner model1071={model1071} onChange={handleChange} />
                            <Button disabled={!isValid} onClick={handleSubmit} variant={'primary'} id="submit" type="submit" className="usa-button margin-top-4 margin-bottom-7">Submit</Button>
                        </>)}
                    </Card>
                </Row>
            </Col>
        </div>
    )
}

export default TenSeventyOne;