import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InputNote, PhoneNumberInput as PhoneInput } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import { Country } from '../../models/Country';

interface Props {
    value?: string,
    className?: string,
    countryID?: number,
    onChange: (value: string) => void
}

const PhoneNumberInput = (props: React.PropsWithChildren<Props>) => {

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(props.value); // This is necessary to allow the component to be unconrolled
    const [country, setCountry] = useState<Country | null>(null);

    const inputNode = React.createRef<HTMLInputElement>();

    useEffect(() => {
        formatPhoneNumber(value);
        FlowProvider.GetCountry(props.countryID).then((result) => {
            if (result) {
                setCountry(result);
            }
        });
    }, [props.countryID]);

    const formatPhoneNumber = (value?: string) => {
        if (value) {
            setLoading(true);
            FlowProvider.FormatValidatePhoneNumber(value, props.countryID).then((result) => {
                if (result && result.valid) {
                    // setError(false);
                    setValue(result.e164PhoneNumber);
                    props.onChange(result.e164PhoneNumber);
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleChange = (value: string) => {
        setValue(value);
        props.onChange(value);

        if (inputNode && document.activeElement !== inputNode.current) {
            formatPhoneNumber(value);
        }
    }

    const handleBlur = () => {
        // Not perfect, but if this is the case then we probably need to validate again
        if (value)
            formatPhoneNumber(value);
    }

    return (
        <PhoneInput ref={inputNode} value={props.value} onChange={handleChange} onBlur={handleBlur} className={`${props.className} dropdown-input`} containerProps={{ className: 'label-colorize' }} iso2CountryCode={country?.isO2CountryCode || undefined} >
            {props.children}
        </PhoneInput>
    )
}

export default PhoneNumberInput;