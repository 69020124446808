import React, { useEffect, useState } from 'react';
import { InputLabel, InputNote, TextInput } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import ButtonSpinner from '../ButtonSpinner';

interface Props {
    value?: string,
    secure?: boolean,
    className?: string,
    placeholder?: string,
    onChange: (value: string) => void,
    onEnter?: () => void
}

const SocialSecurityNumberInput = (props: React.PropsWithChildren<Props>) => {

    const handleChange = (value: string) => {
        const cleaned = value.replace(/\D/g, '');

        // Format as SSN as you type
        const newVal = cleaned.length > 3 && cleaned.length < 6
            ? `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`
            : cleaned.length >= 6
                ? `${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(5, 9)}`
                : cleaned;

        props.onChange(newVal);
    }

    return (
        <TextInput maxLength={11} secure={props.secure} placeholder={props.placeholder} className={props.className} containerProps={{ className: 'label-colorize' }} value={props.value} onEnter={props.onEnter} onChange={handleChange}>
            {props.children}
        </TextInput>
    );
}

export default SocialSecurityNumberInput;