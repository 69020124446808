import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { IoMdBusiness } from 'react-icons/io';
import { AiOutlineUser } from 'react-icons/ai';
import FlowProvider from '../../providers/FlowProvider';
import { SelectOption } from '../../models/SelectOption';

interface Props {
    value?: SelectOption,
    onChange: (value: SelectOption) => void
}

const DealTypeInput = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [dealTypes, setDealTypes] = useState<SelectOption[]>([]);

    useEffect(() => {
        FlowProvider.GetDealTypes().then((result) => {
            if (result)
                setDealTypes(result.options);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading)
        return (
            <div className='d-flex justify-content-center align-items-center my-5'>
                <Spinner animation='border' />
            </div>
        );

    return (
        <Row>
            {dealTypes?.map((dealType) => {
                if (dealType.label.toLowerCase() === 'consumer')
                    return (
                        <Col key='consumer' md='6' xs='12' className='align-items-center justify-content-center d-flex mb-2 mb-md-0'>
                            <Button className={`deal-type-button d-flex flex-column align-items-center ${props.value?.value === dealType.value ? 'active' : ''}`} variant='clear' onClick={() => props.onChange(dealType)}>
                                <div className='deal-type-header py-1'>
                                    <h5 className='m-0'>Personal</h5>
                                </div>

                                <AiOutlineUser size={100} />

                                <span className='w-85'>
                                    For personal use only.
                                </span>
                            </Button>
                        </Col>
                    )
                else if (dealType.label.toLowerCase() === 'commercial') {
                    return (
                        <Col key='commercial' md='6' xs='12' className='align-items-center justify-content-center d-flex mb-2 mb-md-0'>
                            <Button className={`deal-type-button d-flex flex-column align-items-center ${props.value?.value === dealType.value ? 'active' : ''}`} variant='clear' onClick={() => props.onChange(dealType)}>
                                <div className='deal-type-header py-1'>
                                    <h5 className='m-0'>Business</h5>
                                </div>

                                <IoMdBusiness size={80} />

                                <span className='w-85'>
                                    Sole Props, Farmers, LLC's, Corps and LLP's
                                </span>
                            </Button>
                        </Col>
                    )
                }
            })}
        </Row>
    )
}

export default DealTypeInput;