// Component to render the EIN input field
import React, { useState, useRef, useContext } from 'react';
import { InputNote, TextInput } from 'sandhills-react-bootstrap-ui';
import { QuestionGroup } from '../../models/QuestionGroup';
import { StepQuestion } from '../../models/StepQuestion';
import FlowProvider from '../../providers/FlowProvider';
import ButtonSpinner from '../ButtonSpinner';
import { Spinner } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { AppContext } from '../../AppContext';

interface Props {
  value?: string,
  question: StepQuestion,
  allFlowQuestions: StepQuestion[],
  disabled?: boolean,
  className?: string,
  onChange: (question: StepQuestion, value: any) => void
}

const ListingLinkInput = (props: React.PropsWithChildren<Props>) => {
  const context = useContext(AppContext);

  const changeTimeout = useRef<NodeJS.Timeout | null>(null);

  const [loading, setLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const getDetailsForListingLink = (value: string) => {
    setLoading(true);

    FlowProvider.GetListingLinkInformation(value).then((result) => {
      if (result && result.values && inputRef.current?.offsetParent !== null) {

        const lq = props.allFlowQuestions.find(q => q.name.toLowerCase() === 'listing id');
        if (lq) {
          props.onChange(lq, result.values[lq.inputDefinitionID]);
        }

        const tceq = props.allFlowQuestions.find(q => q.name.toLowerCase() === 'total cost of the equipment');
        if (tceq) {
          props.onChange(tceq, result.values[tceq.inputDefinitionID] ?? 0);
        }

        const ccq = props.allFlowQuestions.find(q => q.name.toLowerCase() === 'currency code');
        if (ccq) {
          props.onChange(ccq, result.values[ccq.inputDefinitionID] ?? context.defaultCurrencyCode);
        }

        const ecq = props.allFlowQuestions.find(q => q.name.toLowerCase() === 'equipment category');
        if (ecq) {
          props.onChange(ecq, result.values[ecq.inputDefinitionID]);
        }

        const emaq = props.allFlowQuestions.find(q => q.name.toLowerCase() === 'equipment manufacturer');
        if (emaq) {
          props.onChange(emaq, result.values[emaq.inputDefinitionID]);
        }

        const emoq = props.allFlowQuestions.find(q => q.name.toLowerCase() === 'equipment model');
        if (emoq) {
          props.onChange(emoq, result.values[emoq.inputDefinitionID]);
        }

        const eyq = props.allFlowQuestions.find(q => q.name.toLowerCase() === 'equipment year');
        if (eyq) {
          props.onChange(eyq, result.values[eyq.inputDefinitionID]);
        }

        const eiq = props.allFlowQuestions.find(q => q.name.toLowerCase() === 'equipment image');
        if (eiq) {
          props.onChange(eiq, result.values[eiq.inputDefinitionID]);
        }
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleChange = (value: string) => {
    props.onChange(props.question, value);

    if (changeTimeout.current)
      clearTimeout(changeTimeout.current);

    // Don't want to spam requests
    // Response will handle if the value isn't valid
    changeTimeout.current = setTimeout(() => {
      getDetailsForListingLink(value);
    }, 500);
  }

  return (
    <div className='position-relative'>
      <TextInput ref={inputRef} value={props.value} className={props.className} containerProps={{ className: 'label-colorize' }} onChange={handleChange}>
        {props.children}
      </TextInput>
      {loading && <Spinner animation='border' size='sm' style={{ zIndex: 3, right: '.5rem', bottom: '11px', cursor: 'pointer' }} className='position-absolute' />}
    </div>
  );
}

export default ListingLinkInput;